import clsx from 'clsx';
import React from 'react';

type SidebarItemContainerProps = {
  children: React.ReactNode;
  className?: string;
};
export default function ItemContainer({
  children,
  className,
}: SidebarItemContainerProps) {
  return (
    <div
      className={clsx(
        'w-full rounded-md bg-primary-lighter/5 p-3 dark:bg-dark-500/40',
        className
      )}
    >
      {children}
    </div>
  );
}
