/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import { Alert, Button, Chip, Tooltip, useToast } from '@canvaza/serval-ui';
import {
  CheckIcon,
  InformationCircleIcon,
  LinkIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import { useRouter } from 'next/navigation';
import React, { useEffect, useMemo, useState } from 'react';

import LightBlueButton from '@/common/component/button/LightBlueButton';
import SelectInput from '@/common/component/forms/SelectInput';
import TextInput from '@/common/component/forms/TextInput';
import { MemberRole } from '@/shared/config/members-roles.config';
import { INVITATION, NEW_WORKSPACE } from '@/shared/config/route-links.config';
import {
  useBatchCreateInvitation,
  useCreateInvitation,
  useGetInvitationsByWorkspace,
  useGetWorkspaceMembers,
  useRemoveInvitation,
  useUpdateInvitation,
} from '@/shared/firebase/client/hooks';
import type {
  ICreateInvitation,
  IInvitation,
  IUser,
  IWorkspace,
} from '@/shared/firebase/models';
import useAttributes from '@/shared/hooks/useAttributes';
import useUserAbility from '@/shared/hooks/useUserAbility';

import InviteMessageCard from '../InviteMessageCard';

type InviteEmailsProps = {
  workspace: IWorkspace;
  user: IUser;
  onInvitationSuccessCallback: (isSuccess: boolean) => void;
  onCloseInvitationModal: () => void;
};
export default function InviteEmails({
  user,
  workspace,
  onInvitationSuccessCallback,
  onCloseInvitationModal,
}: InviteEmailsProps) {
  const ability = useUserAbility();
  const [emailUserRole, setEmailUserRole] = useState<MemberRole>(
    MemberRole.Guest
  );
  const [linkUserRole, setLinkUserRole] = useState<MemberRole>(
    MemberRole.Guest
  );

  const [isEditExpiryDate, setIsEditExpiryDate] = useState(false);
  const [showLinkInvitationForm, setShowLinkInvitationForm] = useState(false);
  const [newInvitations, setNewInvitations] = useState<ICreateInvitation[]>([]);

  const [invitationLink, setInvitationLink] = useState<IInvitation | null>(
    null
  );
  const [expireDate, setExpireDate] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [error, setError] = useState('');

  const minDate = moment().format('YYYY-MM-DDTkk:mm');
  const isValidDate = moment(expireDate).isAfter(moment.now());

  const toast = useToast();
  const router = useRouter();
  const { isFreePlan } = useAttributes();

  const { data: invitations, state: invitationsState } =
    useGetInvitationsByWorkspace({
      workspaceId: workspace.id,
      key: 'user-invitation-list',
    });
  const { data: members, state: membersState } = useGetWorkspaceMembers({
    workspaceId: workspace.id,
  });

  const { create: generateInvitationLink, state: generateInvitationLinkState } =
    useCreateInvitation();

  const { remove: removeInvitation, state: removeInvitationState } =
    useRemoveInvitation(invitationLink?.id ?? 'undefined');

  const { update: updateInvitation, state: updateInvitationState } =
    useUpdateInvitation(invitationLink?.id ?? 'undefined');

  const {
    batchCreate: batchCreateInvitation,
    state: batchCreateInvitationState,
  } = useBatchCreateInvitation(newInvitations);

  const openEditExpireDate = () => setIsEditExpiryDate(true);
  const closeEditExpireDate = () => setIsEditExpiryDate(false);

  const expirationDateTimeLabel = useMemo(() => {
    if (invitationLink && invitationLink.expiredAt) {
      return invitationLink.expiredAt.toDate().toLocaleString();
    }
    return null;
  }, [generateInvitationLinkState.isSuccess, invitations]);

  const invitationLinkLabel = useMemo(() => {
    if (invitationLink)
      return `${window.location.host}${INVITATION.url}?invitationId=${invitationLink.id}`;
    return null;
  }, [invitationLink]);

  const validateEmail = () => {
    return String(emailInput)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const resetExpireDate = () => {
    setExpireDate('');
  };

  const openLinkInvitationForm = () => {
    setShowLinkInvitationForm(true);
    resetExpireDate();
  };

  const closeLinkInvitationForm = () => {
    setShowLinkInvitationForm(false);
    resetExpireDate();
  };

  const isAlreadyInvited = useMemo(() => {
    return (
      invitations &&
      invitations.findIndex(
        (invitation) =>
          invitation.type === 'email' && invitation.email === emailInput
      ) !== -1
    );
  }, [invitations, emailInput, members]);

  const addInvitationToList = () => {
    const memberAlreadyExist = members.findIndex(
      (member) => member.user.email === emailInput
    );
    if (memberAlreadyExist !== -1) {
      setError('This email address has already been invited to join.');
      return;
    }
    if (!emailInput) {
      setError('Email is required');
      return;
    }

    if (isAlreadyInvited) {
      setError('This email address has already been invited to join.');
      return;
    }

    const isValidEmail = validateEmail();
    if (!isValidEmail) {
      setError('Email is not valid');
      return;
    }
    const isEmailAlreadyAdded = newInvitations.some(
      (newInvitation) => newInvitation.email === emailInput
    );

    if (isEmailAlreadyAdded) {
      setError('Email already added.');
      setEmailInput('');
      return;
    }

    setNewInvitations((prev) => [
      ...prev,
      {
        id: `${workspace.id}_${emailInput}`,
        email: emailInput,
        expiredAt: null,
        type: 'email',
        workspace: { id: workspace.id, name: workspace.name },
        user: {
          id: user.id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        },
        role: emailUserRole,
      },
    ]);
    setEmailInput('');
    setError('');
    setEmailUserRole(MemberRole.Guest);
  };

  const expiryDateToTimestamp = () => {
    if (expireDate) {
      return Timestamp.fromDate(new Date(expireDate));
    }
    return null;
  };

  const handleKeyDownAddInvitation = (event: any) => {
    if (event.key === 'Enter') {
      addInvitationToList();
    }
  };

  const handleClickAddInvitation = () => {
    addInvitationToList();
  };

  const handleRemoveEmailFromList = (index: number) => {
    const clonedInvitations = [...newInvitations];
    clonedInvitations.splice(index, 1);
    setNewInvitations(clonedInvitations);
  };

  const handleExpireDateChange = (e: React.FormEvent<HTMLInputElement>) => {
    setExpireDate(e.currentTarget.value);
  };

  const handleGenerateLink = () => {
    const newInvitation: ICreateInvitation = {
      type: 'link',
      email: null,
      user: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      },
      workspace: { id: workspace.id, name: workspace.name },
      role: MemberRole.Guest,
      expiredAt: expiryDateToTimestamp(),
    };

    generateInvitationLink(newInvitation);
  };

  const handleCopyInvitationLink = () => {
    if (invitationLinkLabel) navigator.clipboard.writeText(invitationLinkLabel);
  };

  const handleRemoveInvitation = () => {
    removeInvitation();
  };

  const handleUpdateInvitation = () => {
    if (!invitationLink) return;
    const updateInvitationLink: IInvitation = {
      ...invitationLink,
      role: linkUserRole,
      expiredAt: expiryDateToTimestamp(),
    };

    updateInvitation(updateInvitationLink);
  };

  const handelBatchInvitationSent = () => {
    if (newInvitations.length > 0) {
      batchCreateInvitation();
    }
  };

  const resetInvitations = () => {
    setNewInvitations([]);
    setEmailInput('');
    setError('');
    setNewInvitations([]);
  };

  const handleNewWorkspaceRoute = () => {
    router.push(NEW_WORKSPACE.url);
    onCloseInvitationModal();
  };

  useEffect(() => {
    if (generateInvitationLinkState.isSuccess) {
      resetExpireDate();
      setShowLinkInvitationForm(false);
      toast?.pushSuccess('Invitation link generated successfully.');
    }
  }, [generateInvitationLinkState.isSuccess]);

  useEffect(() => {
    if (removeInvitationState.isSuccess) {
      toast?.pushInfo('Invitation link removed successfully.');
      setShowLinkInvitationForm(false);
      closeEditExpireDate();
    }
  }, [removeInvitationState.isSuccess]);

  useEffect(() => {
    if (batchCreateInvitationState.isSuccess) {
      onInvitationSuccessCallback(batchCreateInvitationState.isSuccess);
      toast?.pushSuccess('Invitation sent successfully.');
      resetInvitations();
    }
  }, [batchCreateInvitationState.isSuccess]);

  useEffect(() => {
    if (
      invitationsState.isSuccess ||
      invitations.length > 0 ||
      removeInvitationState.isSuccess ||
      generateInvitationLinkState.isSuccess
    ) {
      const workspaceInvitationLink = invitations.find(
        (invitation) => invitation.type === 'link'
      );

      if (workspaceInvitationLink) {
        setInvitationLink(workspaceInvitationLink);
        setLinkUserRole(workspaceInvitationLink.role);
      } else {
        setInvitationLink(null);
      }
    }
  }, [
    generateInvitationLinkState.isSuccess,
    invitationsState.isSuccess,
    invitations,
    removeInvitationState.isSuccess,
  ]);

  useEffect(() => {
    if (invitationLink?.expiredAt) {
      setExpireDate(
        moment(invitationLink?.expiredAt.toDate()).format('YYYY-MM-DDTkk:mm')
      );
    }
  }, [invitationLink?.expiredAt]);

  useEffect(() => {
    if (updateInvitationState.isSuccess) {
      closeEditExpireDate();
      setShowLinkInvitationForm(false);
      toast?.pushSuccess(
        "The invitation link's expiration date has been successfully updated."
      );
    }
  }, [updateInvitationState.isSuccess]);

  const handleInvitationRoleChange = (
    memberRole: MemberRole,
    index: number
  ) => {
    const clonedNewInvitations = [...newInvitations];
    clonedNewInvitations[index] = {
      ...newInvitations[index],
      role: memberRole,
    } as IInvitation;
    setNewInvitations(clonedNewInvitations);
  };

  return (
    <div className={clsx('flex w-full flex-col gap-9 px-2')}>
      <div className="flex flex-col gap-2">
        {/* Show upgrade status */}
        {workspace.personal && (
          <InviteMessageCard
            description="Workspace invitations are unavailable with personal workspace. To invite others, consider creating a separate, sharable workspace."
            actionButton="workspace"
            onHandleNewWorkspaceRoute={handleNewWorkspaceRoute}
          />
        )}

        {!workspace.personal && isFreePlan && (
          <InviteMessageCard
            description="Workspace invitations are unavailable with the free plan. To invite others, consider upgrading to a paid plan."
            actionButton="upgrade"
          />
        )}
        {/* Email form */}
        {!isEditExpiryDate && !showLinkInvitationForm && (
          <>
            <div className="flex w-full flex-col gap-1">
              <span>Add email address </span>
              <div className="flex items-start justify-start gap-3">
                <div className="flex w-full flex-col gap-2 overflow-hidden">
                  <TextInput
                    value={emailInput}
                    fullWidth
                    size="small"
                    type="email"
                    placeholder="Email address"
                    disabled={
                      isFreePlan ||
                      workspace.personal ||
                      !ability.can('send', 'invitation')
                    }
                    onChange={(e) => {
                      setEmailInput(e.currentTarget.value);
                    }}
                    className="!mx-0 truncate pr-24 text-sm sm:text-base"
                    inputAdornment={{
                      endAdornment: (
                        <div className="flex items-center gap-0">
                          <SelectInput
                            size="small"
                            disabled={
                              isFreePlan ||
                              workspace.personal ||
                              !ability.can('send', 'invitation')
                            }
                            value={emailUserRole}
                            onChange={(e) =>
                              setEmailUserRole(
                                e.currentTarget.value as MemberRole
                              )
                            }
                            className="w-[5.3rem] !border-none pr-0 text-sm !ring-0 focus:ring-0 sm:text-base"
                            options={Object.keys(MemberRole).map((role) => ({
                              label: role,
                              value: role,
                            }))}
                          />
                          <Tooltip
                            placement="right-end"
                            className="!max-w-[30rem]"
                            content={
                              emailUserRole === MemberRole.Admin
                                ? 'All permissions'
                                : emailUserRole === MemberRole.Editor
                                ? 'Editor permissions'
                                : 'Views permissions'
                            }
                            arrow={false}
                          >
                            <InformationCircleIcon className="w-4" />
                          </Tooltip>
                        </div>
                      ),
                    }}
                    onKeyDown={handleKeyDownAddInvitation}
                  />
                  {error && (
                    <p className="text-sm text-error dark:text-error-light">
                      {error}
                    </p>
                  )}
                </div>

                <div className="flex justify-center">
                  <LightBlueButton
                    icon={
                      <PlusIcon className="w-4 stroke-dark dark:stroke-dark-lighter" />
                    }
                    disabled={
                      !emailInput ||
                      isFreePlan ||
                      workspace.personal ||
                      !ability.can('send', 'invitation')
                    }
                    title={'Add Email'}
                    onClick={handleClickAddInvitation}
                  />
                </div>
              </div>
              {!isFreePlan &&
                !workspace.personal &&
                !ability.can('send', 'invitation') && (
                  <Alert
                    type="error"
                    closable
                    showIcon
                    message="You cannot invite other member"
                    description={
                      <p>
                        You are member of this workspace with
                        <b>View privilege</b>.
                      </p>
                    }
                  />
                )}
            </div>
            {/* Invitation list */}
            {!membersState.isLoading && newInvitations.length > 0 && (
              <div className={clsx('flex w-full flex-col gap-4')}>
                <h3 className="font-semibold">Added invitations</h3>
                <ul className="flex flex-col gap-3">
                  {newInvitations.map((invitation, index) => (
                    <li
                      key={index}
                      className="flex w-full items-center justify-between gap-2"
                    >
                      <div className="flex w-full flex-wrap items-center justify-between gap-2">
                        <span className="flex items-center gap-3 overflow-hidden">
                          <CheckIcon className="w-4 stroke-success stroke-2 dark:stroke-success-light" />
                          <span className="truncate">{invitation.email}</span>
                        </span>
                        <div className="flex items-center gap-2">
                          <SelectInput
                            className="w-[5.3rem] focus:ring-0"
                            size="small"
                            disabled={isFreePlan || workspace.personal}
                            value={invitation.role}
                            onChange={(e) =>
                              handleInvitationRoleChange(
                                e.currentTarget.value as MemberRole,
                                index
                              )
                            }
                            options={Object.keys(MemberRole).map((role) => ({
                              label: role,
                              value: role,
                            }))}
                          />
                          <button
                            disabled={isFreePlan || workspace.personal}
                            className="text-sm text-error dark:text-error-light"
                            onClick={() => handleRemoveEmailFromList(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {newInvitations.length > 0 && (
              <div className="flex w-full justify-end pt-8">
                <Button
                  variant="contained"
                  onClick={handelBatchInvitationSent}
                  size="small-x"
                  isLoading={batchCreateInvitationState.isLoading}
                  disabled={
                    batchCreateInvitationState.isLoading ||
                    isFreePlan ||
                    workspace.personal
                  }
                >
                  <span className="text-sm">Send Invitation</span>
                </Button>
              </div>
            )}
          </>
        )}

        {/* Generate link controls */}
        {!invitationLinkLabel && showLinkInvitationForm && (
          <div className="flex w-full flex-col items-end justify-between gap-4">
            <div className="flex w-full flex-col items-end gap-2 xs:flex-row">
              <TextInput
                label="Invitation link expire date"
                type="datetime-local"
                size="small"
                fullWidth
                min={minDate}
                value={expireDate}
                disabled={isFreePlan || workspace.personal}
                onChange={handleExpireDateChange}
              />
              <SelectInput
                disabled={isFreePlan || workspace.personal}
                size="small"
                className="w-[5.3rem]"
                value={linkUserRole}
                onChange={(e) =>
                  setLinkUserRole(e.currentTarget.value as MemberRole)
                }
                options={Object.keys(MemberRole).map((role) => ({
                  label: role,
                  value: role,
                }))}
              />
            </div>

            <div className="flex gap-4 ">
              <Button
                variant="text"
                disabled={isFreePlan}
                onClick={closeLinkInvitationForm}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleGenerateLink}
                disabled={
                  moment(expireDate).isBefore(moment.now()) ||
                  generateInvitationLinkState.isLoading ||
                  isFreePlan ||
                  workspace.personal ||
                  !ability.can('send', 'invitation')
                }
                isLoading={generateInvitationLinkState.isLoading}
                className="whitespace-nowrap"
              >
                Generate link
              </Button>
            </div>
          </div>
        )}

        {/* Invitation label */}
        {invitationLinkLabel && (
          <div
            className={clsx(
              'flex w-full flex-col gap-4 rounded bg-primary/5 p-1',
              { 'blur-sm select-none': isFreePlan }
            )}
          >
            <div
              className={clsx(
                'flex w-full flex-col gap-4 rounded-md border p-2 dark:border-dark-500'
              )}
            >
              <div
                className={clsx('flex w-full justify-between gap-2', {
                  'flex-col lg:flex-row items-start lg:items-center':
                    isEditExpiryDate,
                  'flex flex-col sm:flex-row items-start sm:items-center':
                    !isEditExpiryDate,
                })}
              >
                {!isEditExpiryDate && (
                  <div className="flex items-center gap-2 whitespace-nowrap">
                    <span className="text-xs md:text-sm">Expire date:</span>

                    <div className="text-dark-400 dark:text-dark-300">
                      {invitationLink?.expiredAt ? (
                        <span className="text-xs md:text-sm">
                          {expirationDateTimeLabel || expireDate}
                        </span>
                      ) : (
                        <span>Never expire</span>
                      )}
                    </div>
                  </div>
                )}

                <div className="flex flex-wrap items-center gap-3">
                  {isEditExpiryDate && (
                    <TextInput
                      className="text-xs"
                      type="datetime-local"
                      size="small"
                      disabled={isFreePlan || workspace.personal}
                      fullWidth
                      value={expireDate}
                      min={minDate}
                      onChange={handleExpireDateChange}
                    />
                  )}
                  <Chip
                    size="small"
                    color="success"
                    label={invitationLink?.role ?? MemberRole.Guest}
                  />
                  <div>
                    {!isEditExpiryDate && (
                      <Button
                        disabled={isFreePlan}
                        variant="contained"
                        size="small-x"
                        onClick={openEditExpireDate}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                  <div>
                    {isEditExpiryDate && (
                      <div className={clsx('flex flex-row justify-end gap-1')}>
                        <Button
                          variant="contained"
                          size="small-x"
                          onClick={handleUpdateInvitation}
                          isLoading={updateInvitationState.isLoading}
                          disabled={
                            invitationsState.isLoading ||
                            updateInvitationState.isLoading ||
                            isFreePlan ||
                            workspace.personal ||
                            (!isValidDate && !!expireDate)
                          }
                        >
                          Update
                        </Button>
                        <Button
                          color="dark"
                          size="small-x"
                          disabled={isFreePlan || workspace.personal}
                          onClick={closeEditExpireDate}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full flex-wrap items-center justify-between gap-2 rounded-md border p-2 dark:border-dark-500">
              <div
                className={clsx('inline-block text-xs sm:text-sm', {
                  'flex flex-col gap-2 opacity-40':
                    !isValidDate && invitationLink?.expiredAt,
                })}
              >
                {invitationLinkLabel}
              </div>
              {!isValidDate && invitationLink?.expiredAt && (
                <div className="flex items-center gap-2">
                  <Chip
                    label="Expired"
                    size="small"
                    color="error"
                    variant="outlined"
                  />
                  <div>
                    <Button
                      size="small-x"
                      onClick={handleRemoveInvitation}
                      isLoading={removeInvitationState.isLoading}
                      disabled={
                        removeInvitationState.isLoading ||
                        isFreePlan ||
                        workspace.personal
                      }
                      variant="text"
                      color="error"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              )}
              <div className="flex items-start gap-4 sm:w-auto">
                {(isValidDate || !invitationLink?.expiredAt) && (
                  <>
                    <Button
                      size="small-x"
                      onClick={handleCopyInvitationLink}
                      variant="contained"
                      disabled={isFreePlan || workspace.personal}
                    >
                      Copy
                    </Button>
                    <Button
                      size="small-x"
                      onClick={handleRemoveInvitation}
                      isLoading={removeInvitationState.isLoading}
                      disabled={
                        removeInvitationState.isLoading ||
                        isFreePlan ||
                        workspace.personal
                      }
                      variant="text"
                      color="error"
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {!invitationLinkLabel && !showLinkInvitationForm && (
          <div
            className={clsx(
              'flex w-full flex-col gap-4 rounded bg-primary/5 p-1'
            )}
          >
            <Button
              disabled={
                isFreePlan ||
                workspace.personal ||
                !ability.can('send', 'invitation')
              }
              variant="text"
              startIcon={<LinkIcon className="w-6" />}
              size="large"
              onClick={openLinkInvitationForm}
            >
              <span className="px-2 font-normal">Get invitation link</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
