import { useEffect, useState } from 'react';

import { useGetItems } from '@/shared/firebase/client/hooks/item.hooks';
import type {
  IPlanAttributes,
  IVideoAnalytics,
  IVideoQuality,
} from '@/shared/firebase/models/stripe-payment.interface';

import { useGetWorkspaceGeneralSetting } from '../firebase/client/hooks';
import { useActiveWorkspace } from './useActiveWorkspace';

export default function useAttributes() {
  const [attributes, setAttributes] = useState<IPlanAttributes | null>(null);

  const { data: freePlanAttributes, state: freePlanAttributesState } =
    useGetWorkspaceGeneralSetting({ key: 'general-settings-attributes' });

  const { activeWorkspace, state: activeWorkspaceState } = useActiveWorkspace();

  const { data: items, state: itemsCountState } = useGetItems({
    workspaceId: activeWorkspace?.id,
    enabled: !!activeWorkspace?.id,
    key: 'user-attributes-item-list',
  });

  useEffect(() => {
    if (activeWorkspaceState?.isSuccess && activeWorkspace?.attributes) {
      setAttributes(activeWorkspace.attributes);
    } else if (
      freePlanAttributesState.isSuccess &&
      freePlanAttributes?.attributes
    ) {
      setAttributes(freePlanAttributes.attributes);
    } else {
      setAttributes(null);
    }
  }, [
    freePlanAttributesState.isSuccess,
    activeWorkspaceState?.isSuccess,
    freePlanAttributesState.isRefetching,
    activeWorkspaceState?.isRefetching,
    activeWorkspace?.attributes,
    freePlanAttributes,
  ]);

  const isValidDuration = (microSeconds: number): boolean => {
    return (
      attributes !== null &&
      (attributes.video.duration === 'inf' ||
        (typeof attributes.video.duration === 'number' &&
          attributes.video.duration > microSeconds / 60))
    );
  };

  const isFreePlan = () => {
    return activeWorkspace?.attributes === null;
  };

  const canAddVideos = (): boolean => {
    return (
      attributes !== null &&
      (attributes.video.limit === 'inf' ||
        (typeof attributes.video.limit === 'number' &&
          attributes.video.limit > items.length))
    );
  };

  const isValidQuality = (quality: IVideoQuality): boolean => {
    const validVideoQuality: IVideoQuality[] = ['720p'];

    if (attributes !== null) {
      if (attributes.video.quality === '1080p') {
        validVideoQuality.push('1080p');
      }

      if (attributes.video.quality === '4k') {
        validVideoQuality.push('1080p', '4k');
      }
    }

    return attributes !== null && validVideoQuality.includes(quality);
  };

  const isValidAnalysisLevel = (analytics: IVideoAnalytics): boolean => {
    return attributes !== null && attributes.video.analytics === analytics;
  };

  const isAllowSSO = (): boolean => {
    return attributes !== null && attributes.allowSSO;
  };

  const isAllowCustomizeBrand = (): boolean => {
    return attributes !== null && attributes.customizableBrand;
  };

  const isAllowSpeechToText = (): boolean => {
    return attributes !== null && attributes.allowSpeechToText;
  };

  const slack = (): boolean => {
    return attributes !== null && attributes.integration.slack;
  };

  const github = (): boolean => {
    return attributes !== null && attributes.integration.github;
  };

  const gmail = (): boolean => {
    return attributes !== null && attributes.integration.gmail;
  };

  const saleforce = (): boolean => {
    return attributes !== null && attributes.integration.saleForce;
  };

  const upwork = (): boolean => {
    return attributes !== null && attributes.integration.upWork;
  };

  const zoom = (): boolean => {
    return attributes !== null && attributes.integration.zoom;
  };

  return {
    state: {
      isLoading:
        itemsCountState.isLoading ||
        activeWorkspaceState?.isLoading ||
        activeWorkspaceState?.isLoading,
      isError:
        itemsCountState.isError ||
        activeWorkspaceState?.isError ||
        activeWorkspaceState?.isError,
      isSuccess:
        itemsCountState.isSuccess ||
        activeWorkspaceState?.isSuccess ||
        activeWorkspaceState?.isSuccess,
    },
    attributes,
    isFreePlan: isFreePlan(),
    canAddVideo: canAddVideos(),
    canUseSSO: isAllowSSO(),
    canCustomizeBrand: isAllowCustomizeBrand(),
    canUseSpeechToText: isAllowSpeechToText(),
    canIntegrateWithZoom: zoom(),
    canIntegrateWithSlack: slack(),
    canIntegrateWithGithub: github(),
    canIntegrateWithGmail: gmail(),
    canIntegrateWithSaleforce: saleforce(),
    canIntegrateWithUpwork: upwork(),
    isValidAnalysisLevel,
    isValidDuration,
    isValidQuality,
  };
}
