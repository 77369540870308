export enum MemberRole {
  'Admin' = 'Admin',
  'Editor' = 'Editor',
  'Guest' = 'Guest',
}

export type IMemberRolePermission = {
  [key in keyof typeof MemberRole]: Permissions[];
};

export enum Permissions {
  'view_any-video' = 'view_any-video',
  'view-video' = 'view-video',
  'upload-video' = 'upload-video',
  'record-video' = 'record-video',
  'update-video' = 'update-video',
  'delete-video' = 'delete-video',

  'view_any-collection' = 'view_any-collection',
  'create-collection' = 'create-collection',
  'view-collection' = 'view-collection',
  'update-collection' = 'update-collection',
  'delete-collection' = 'delete-collection',

  'view_any-invoice' = 'view-any-invoice',
  'view-invoice' = 'view-invoice',

  'view_any-workspace' = 'view_any-workspace',
  'view-workspace' = 'view-workspace',
  'update-workspace' = 'update-workspace',
  'delete-workspace' = 'delete-workspace',

  'view_any-invitation' = 'view_any-invitation',
  'view-invitation' = 'view-invitation',
  'send-invitation' = 'send-invitation',
  'delete-invitation' = 'delete-invitation',

  'view_any-member' = 'view_any-member',
  'view-member' = 'view-member',
  'update-member' = 'update-member',
  'delete-member' = 'delete-member',

  'view_any-integration' = 'view_any-integration',
  'view-integration' = 'view-integration',
  'create-integration' = 'add-integration',
  'update-integration' = 'update-integration',
  'delete-integration' = 'delete-integration',

  'view_any-sso_configuration' = 'view_any-sso_configuration',
  'view-sso_configuration' = 'view-sso_configuration',
  'update-sso_configuration' = 'update-sso_configuration',
  'delete-sso_configuration' = 'delete-sso_configuration',

  'view_any-workspace_analytic' = 'view_any-workspace_analytic',
  'view-workspace_analytic' = 'view-workspace_analytic',

  'view_any-video_analytic' = 'view_any-video_analytic',
  'view-video_analytic' = 'view-video_analytic',

  'view_any-subscription' = 'view_any-subscription',
  'view-subscription' = 'view-subscription',
  'upgrade-subscription' = 'upgrade-subscription',
  'stop-subscription' = 'stop-subscription',
  'pause-subscription' = 'pause-subscription',
  'resume-subscription' = 'resume-subscription',
}

export type IUserActions =
  | 'view_any'
  | 'view'
  | 'create'
  | 'upload'
  | 'send'
  | 'record'
  | 'update'
  | 'delete'
  | 'upgrade'
  | 'stop'
  | 'pause'
  | 'resume';

export type IUserSubjects =
  | 'video'
  | 'collection'
  | 'invitation'
  | 'member'
  | 'integration'
  | 'sso_configuration'
  | 'workspace'
  | 'workspace_analytic'
  | 'video_analytic'
  | 'subscription'
  | 'invoice';

export const MEMBER_ROLE_PERMISSIONS: IMemberRolePermission = {
  [MemberRole.Admin]: [...(Object.keys(Permissions) as Permissions[])],
  [MemberRole.Editor]: [
    Permissions['view_any-video'],
    Permissions['view-video'],
    Permissions['upload-video'],
    Permissions['record-video'],
    Permissions['update-video'],
    Permissions['delete-video'],

    Permissions['view_any-collection'],
    Permissions['view-collection'],
    Permissions['create-collection'],
    Permissions['update-collection'],
    Permissions['delete-collection'],

    Permissions['view_any-member'],
    Permissions['view-member'],

    Permissions['view-video_analytic'],
  ],
  [MemberRole.Guest]: [
    Permissions['view_any-collection'],
    Permissions['view-collection'],
    Permissions['view_any-video'],
    Permissions['view-video'],
    Permissions['view-video_analytic'],
    Permissions['view_any-member'],
    Permissions['view-member'],
  ],
};
