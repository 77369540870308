import { Button } from '@canvaza/serval-ui';
import { PlusIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import React from 'react';

import { UPGRADE } from '@/shared/config/route-links.config';

type ActionButtonType = 'upgrade' | 'workspace';

type InviteMessageCardProps = {
  description: string;
  actionButton?: ActionButtonType;
  onHandleNewWorkspaceRoute?: () => void;
};
export default function InviteMessageCard({
  description,
  actionButton,
  onHandleNewWorkspaceRoute,
}: InviteMessageCardProps) {
  return (
    <div className="flex flex-col items-start justify-center gap-3 rounded bg-success-light/10 px-4 py-3 xs:flex-row xs:items-center lg:gap-7 lg:p-6">
      <p className="text-xs lg:text-sm">{description}</p>
      {actionButton === 'workspace' && (
        <Button
          variant="contained"
          startIcon={<PlusIcon className="w-6" />}
          className="h-11 w-44 whitespace-nowrap text-sm font-normal"
          onClick={onHandleNewWorkspaceRoute}
        >
          Create workspace
        </Button>
      )}
      {actionButton === 'upgrade' && (
        <Link
          href={UPGRADE.url}
          className="rounded border border-success-light px-4 py-2"
        >
          <span className="text-xs text-success-light md:text-sm">Upgrade</span>
        </Link>
      )}
    </div>
  );
}
