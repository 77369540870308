import { Modal } from '@canvaza/serval-ui';
import React from 'react';

import LightBlueButton from '@/common/component/button/LightBlueButton';
import { useActiveWorkspace } from '@/shared/hooks/useActiveWorkspace';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { GradientLoading, SuccessCheckIcon } from '@/shared/utils/svg';

import InviteEmails from '../../workspace/new-workspace/InviteEmails';

type ChildrenArgs = {
  onOpenInvitationModal: () => void;
};
type InviteFriendsRenderProps = {
  children: (args: ChildrenArgs) => React.ReactNode;
};
export default function InviteFriendsRender({
  children,
}: InviteFriendsRenderProps) {
  const [modals, setModals] = React.useState({
    invite: false,
    successInfo: false,
  });

  const { activeWorkspace, state: activeWorkspaceState } = useActiveWorkspace();
  const { currentUser } = useCurrentUser();

  const openInvitationModal = () => {
    setModals((prev) => ({ ...prev, invite: true }));
  };

  const closeInvitationModal = () => {
    setModals((prev) => ({ ...prev, invite: false }));
  };

  const openInvitationSuccessModal = () => {
    setModals((prev) => ({ ...prev, successInfo: true }));
  };

  const closeInvitationSuccessModal = () => {
    setModals((prev) => ({ ...prev, successInfo: false }));
  };

  const handleSendInvitationSuccess = (isSuccess: boolean) => {
    if (isSuccess) {
      openInvitationSuccessModal();
      closeInvitationModal();
    }
  };

  return (
    <>
      {children({
        onOpenInvitationModal: openInvitationModal,
      })}
      <Modal
        open={modals.invite}
        title={'Invite people to your workspace'}
        onClose={closeInvitationModal}
        maskClosable
        actions={<></>}
      >
        <div className="flex w-full items-center justify-center gap-3 px-2 pt-6">
          {activeWorkspaceState?.isLoading && (
            <GradientLoading className="h-12 w-12 animate-spin" />
          )}
          {activeWorkspaceState?.isSuccess &&
            activeWorkspace &&
            currentUser && (
              <InviteEmails
                onInvitationSuccessCallback={handleSendInvitationSuccess}
                workspace={activeWorkspace}
                user={currentUser}
                onCloseInvitationModal={closeInvitationModal}
              />
            )}
        </div>
      </Modal>

      <Modal
        open={modals.successInfo}
        onClose={closeInvitationSuccessModal}
        maskClosable
        actions={
          <div className="flex w-full items-center justify-end gap-2 p-2">
            <LightBlueButton title="Ok" onClick={closeInvitationSuccessModal} />
          </div>
        }
      >
        <div className="flex w-full flex-col items-center justify-center gap-3 px-2 py-6">
          <div className="flex w-2/3 items-center justify-center">
            <SuccessCheckIcon className="w-24" />
          </div>
          <p className="w-full text-center text-base md:w-4/5 md:text-lg">
            Your invitation to join <strong> {activeWorkspace?.name} </strong>{' '}
            have been sent successfully
          </p>
          <p className="text-center text-xs text-primary-light">Canvaza</p>
        </div>
      </Modal>
    </>
  );
}
